import type { FormId, UtilitiesForm } from '@utilities/types';
import type {
  AccountStatsResponse,
  UtilitiesAccountsResponse,
  UtilitiesOrdersError,
  UtilitiesOrdersPropertiesResponse,
  UtilitiesOrdersResponse,
} from '@utilities/types/responses';
import type {
  CreateOrUpdateOrderFormPayload,
  FormUploadPayload,
  UtilitiesOrdersPropertiesPayload,
  UtilitiesQueryPayload,
} from '@utilities/types/requests';

export default {
  accounts: {
    get(query: UtilitiesQueryPayload) {
      return useApiFetch<UtilitiesAccountsResponse>(
        useZiggy('agents.utilities.accounts.index'),
        {
          method: 'GET',
          query,
        },
      );
    },

    create() {
      return useApiFetch(useZiggy('agents.utilities.accounts.create'), {
        method: 'GET',
      });
    },

    show(id: FormId) {
      return useApiFetch(useZiggy('agents.utilities.accounts.show', id), {
        method: 'GET',
      });
    },

    store(body = {}) {
      return useApiFetch(useZiggy('agents.utilities.accounts.store'), {
        method: 'POST',
        body,
      });
    },

    destroy(body: { forms: FormId[] }) {
      return useApiFetch(useZiggy('agents.utilities.accounts.destroy'), {
        method: 'DELETE',
        body,
      });
    },

    stats(query = {}) {
      return useApiFetch<AccountStatsResponse>(
        useZiggy('agents.utilities.accounts.stats'),
        {
          method: 'GET',
          query,
        },
      );
    },
  },

  orders: {
    show(id: FormId) {
      return useApiFetch<UtilitiesOrdersResponse, UtilitiesOrdersError>(
        useZiggy('agents.utilities.orders.show', id),
        {
          method: 'GET',
        },
      );
    },

    store(body: CreateOrUpdateOrderFormPayload) {
      return useApiFetch<UtilitiesOrdersResponse, UtilitiesOrdersError>(
        useZiggy('agents.utilities.orders.store'),
        {
          method: 'POST',
          body,
          watch: false,
        },
      );
    },

    update(body: CreateOrUpdateOrderFormPayload, id: FormId) {
      return useApiFetch<UtilitiesOrdersResponse, UtilitiesOrdersError>(
        useZiggy('agents.utilities.orders.update', id),
        {
          method: 'PUT',
          body,
        },
      );
    },

    void(id: FormId) {
      return useApiFetch(useZiggy('agents.utilities.orders.void', id), {
        method: 'PATCH',
      });
    },

    properties(query: UtilitiesOrdersPropertiesPayload) {
      return useApiFetch<UtilitiesOrdersPropertiesResponse>(
        useZiggy('agents.utilities.orders.properties'),
        {
          method: 'GET',
          query,
        },
      );
    },

    send(id: FormId) {
      return useApiFetch(useZiggy('agents.utilities.orders.send', id), {
        method: 'PATCH',
      });
    },

    resend(id: FormId) {
      return useApiFetch(useZiggy('agents.utilities.orders.resend', id), {
        method: 'PATCH',
      });
    },

    upload(body: { forms: FormUploadPayload[] }) {
      return useApiFetch(useZiggy('agents.utilities.orders.upload'), {
        method: 'POST',
        body,
      });
    },

    clone(id: FormId) {
      return useApiFetch<UtilitiesForm>(
        useZiggy('agents.utilities.orders.clone', id),
        {
          method: 'POST',
        },
      );
    },

    previewLink(id: FormId) {
      return useApiFetch(useZiggy('agents.utilities.orders.preview', id));
    },
  },
};
